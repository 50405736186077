

import { useTranslation, Trans } from "react-i18next"

export function Text({ ns, children, options, path, className, ...rest }: any) {
    const { t } = useTranslation(ns || 'common')
    let key     = path ? `${path}.${children}` : `${children}`;
    return (
        <span className={className} {...rest}>
            <Trans i18nKey={key}  t={t} tOptions={options} />
        </span>
    )
}
