import { faCircle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigation } from "@remix-run/react"
import { useEffect, useState, useRef } from "react"
import { cn } from "~/lib/utils"

export function GlobalLoading() {
    const navigation = useNavigation()
    const active = true

    const ref = useRef<HTMLDivElement>(null)
    const [animationComplete, setAnimationComplete] = useState(true)

    return (
        <div>
            <div
                role="progressbar"
                title="progressbar"
                aria-hidden={!active}
                aria-valuetext={active ? "Loading" : undefined}
                className="fixed inset-x-0 top-0 left-0 z-100 h-1 animate-pulse"
            >
                <div
                    ref={ref}
                    className={cn (
                        "h-full bg-gradient-to-r from-highlight to-primary transition-all duration-500 ease-in-out",
                        navigation.state === "idle" && animationComplete && "w-0 opacity-0 transition-none",
                        navigation.state === "submitting" && "w-4/12",
                        navigation.state === "loading" && "w-10/12",
                        navigation.state === "idle" && !animationComplete && "w-full",
                    )}
                />
            </div>
            <div
                role="progressbar"
                title="blocker"
                aria-hidden={!active}
                aria-valuetext={active ? "Loading" : undefined}
                className={cn(
                    "absolute top-0 right-0 -bottom-full left-0 z-90 pointer-events-auto",
                    "flex justify-center bg-black/40",
                    navigation.state === "idle" && animationComplete && "hidden",
                    navigation.state === "submitting" && "show",
                    navigation.state === "loading" && "show",
                    navigation.state === "idle" && !animationComplete && "show",
                )}
            >
                <div
                    className="absolute top-0 right-0 bottom-0 left-0 overflow-hidden"
                >
                    <FontAwesomeIcon
                        icon={faCircle}
                        className="text-white/60 animate-ping w-screen h-screen"
                    />
                </div>
            </div>
        </div>
    )
}