
import { User } from "@/types"
import { Button } from "@/components/ui/button"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faMoonStars, faSunBright } from '@fortawesome/pro-light-svg-icons'
import { useSubmit, useNavigate } from "@remix-run/react"

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Text } from "@/components/text"

import { useTranslation } from "react-i18next"
import { Theme, useTheme } from "remix-themes"

export function SettingsNavigation({ user, terminal }: { user: User, terminal: any }) {
    const i18nPath     = "navigation.settings";
    const { i18n }     = useTranslation()
    const [, setTheme] = useTheme()
    const submit       = useSubmit()
    const navigate     = useNavigate()

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    className="!m-0"
                    variant="outline"
                    size="icon"
                >
                    <FontAwesomeIcon icon={faGear} className="h-[1.2rem] w-[1.2rem] scale-100 transition-all" />
                    <span className="sr-only"><Text path={i18nPath}>user</Text></span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56 mr-4">
                <DropdownMenuLabel>{user.firstName} {user.lastName}</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                    <DropdownMenuItem disabled>
                        <Text path={i18nPath}>profile</Text>
                    </DropdownMenuItem>
                    <DropdownMenuItem disabled>
                        <Text path={i18nPath}>timetracking</Text>
                    </DropdownMenuItem>
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
                <DropdownMenuSub>
                    <DropdownMenuSubTrigger><Text path={`${i18nPath}.language`}>index</Text></DropdownMenuSubTrigger>
                    <DropdownMenuPortal>
                        <DropdownMenuSubContent>
                            <DropdownMenuItem onClick={() => i18n.changeLanguage('en')} ><Text path={`${i18nPath}.language`}>english</Text></DropdownMenuItem>
                            <DropdownMenuItem onClick={() => i18n.changeLanguage('de')} ><Text path={`${i18nPath}.language`}>german</Text></DropdownMenuItem>
                            <DropdownMenuItem onClick={() => i18n.changeLanguage('hu')} ><Text path={`${i18nPath}.language`}>hungarian</Text></DropdownMenuItem>
                        </DropdownMenuSubContent>
                    </DropdownMenuPortal>
                </DropdownMenuSub>
                <DropdownMenuSub>
                    <DropdownMenuSubTrigger><Text path={`${i18nPath}.appearance`}>index</Text></DropdownMenuSubTrigger>
                    <DropdownMenuPortal>
                        <DropdownMenuSubContent>
                            <DropdownMenuItem onClick={() => setTheme(Theme.LIGHT)} >
                                <FontAwesomeIcon icon={faSunBright} className="mr-2 w-4" />
                                <Text path={`${i18nPath}.appearance`}>light</Text>
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={() => setTheme(Theme.DARK)} >
                                <FontAwesomeIcon icon={faMoonStars} className="mr-2 w-4" />
                                <Text path={`${i18nPath}.appearance`}>dark</Text>
                            </DropdownMenuItem>
                        </DropdownMenuSubContent>
                    </DropdownMenuPortal>
                </DropdownMenuSub>
                <DropdownMenuItem disabled>
                    <Text path={i18nPath}>settings</Text>
                </DropdownMenuItem>
                <DropdownMenuItem disabled>
                    <Text path={i18nPath}>support</Text>
                </DropdownMenuItem>
                <DropdownMenuItem
                    onClick={() => navigate("/system/printing")}
                >
                    <Text path={i18nPath}>printing</Text>
                </DropdownMenuItem>
                {terminal?.id && (
                    <DropdownMenuItem
                        onClick={() => submit({}, { method: "POST", action: "/action/set-terminal" })}
                    >
                        <Text
                            path={i18nPath}
                            options={{ terminal: terminal.name }}
                        >
                            diconnectTerminal
                        </Text>
                    </DropdownMenuItem>
                )} 
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                    <div
                        className="block w-full"
                        onClick={() => submit({
                            },
                            {
                                method: "GET",
                                action: '/auth/logout'
                            })
                        }
                    >
                        <Text path={i18nPath}>logout</Text>
                    </div>
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}