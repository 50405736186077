
import { useEffect, useState, useCallback } from "react"
import { useNavigate } from "@remix-run/react"
import { cn } from "@/lib/utils"
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
} from "@/components/ui/command"
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle
} from "@/components/ui/dialog"
import { Button } from "../ui/button"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faSearch } from '@fortawesome/pro-light-svg-icons'
import { Text } from '@/components/text'

export function CommandNavigation({ user, navigation, ...props }: any) {
    const i18nPath = "navigation.command"
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()

    // useEffect(() => {
    //     const down = (e: KeyboardEvent) => {
    //         if ((e.key === "k" && (e.metaKey || e.ctrlKey)) || e.key === "/") {
    //             if (
    //                 (e.target instanceof HTMLElement && e.target.isContentEditable) ||
    //                 e.target instanceof HTMLInputElement ||
    //                 e.target instanceof HTMLTextAreaElement ||
    //                 e.target instanceof HTMLSelectElement
    //             ) {
    //                 return
    //             }

    //             e.preventDefault()
    //             setOpen((open) => !open)
    //         }
    //     }

    //     document.addEventListener("keydown", down)
    //     return () => document.removeEventListener("keydown", down)
    // }, [])
    
    const runCommand = useCallback((command: () => unknown) => {
        setOpen(false)
        command()
    }, [])

    const NavCommandItem = ({ item, parent } : any) => (
        <>
            <CommandItem
                onSelect={() => {
                    if (!item.disabled) {
                        runCommand(() => navigate(item.to))
                    }
                }}
            >
                {item.icon && <FontAwesomeIcon icon={['fal', item.icon]} className="mr-2 h-4 w-4" />}
                {parent && parent.filter((p: any) => p.title ).map((p: any, index: number) => (
                    <span key={ index } className="flex flex-row">
                        <span
                            className={cn(
                                item.disabled && "cursor-not-allowed opacity-50",
                            )}
                        ><Text>{p.title}</Text></span>
                        <FontAwesomeIcon icon={faChevronRight} className="m-0 mt-2 mx-1 h-2 w-2" />
                    </span>
                ))}
                <span
                    className={cn(
                        item.disabled && "cursor-not-allowed opacity-50",
                    )}
                ><Text>{item.title}</Text></span>
            </CommandItem>
            {item.navigation && item.navigation.map((subItem: any, subIndex: number) => (
                <NavCommandItem key={subIndex} item={subItem} parent={[parent ? parent : {}, item]} />
            ))}
        </>
    )

    return (
        <div className="hidden">
            <div className={cn("hidden lg:block")}>
                <Button
                    variant="outline"
                    className={cn(
                        "relative h-9 w-full justify-start rounded-[0.5rem] bg-background text-sm font-normal text-muted-foreground shadow-none sm:pr-12 md:w-40 lg:w-64"
                    )}
                    onClick={() => setOpen(true)}
                    {...props}
                >
                    <span className="hidden lg:inline-flex"><Text path={i18nPath}>search</Text></span>
                    <kbd className="pointer-events-none absolute right-[0.3rem] top-[0.3rem] hidden h-5 mt-0.5 mr-0.5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex">
                        <span className="text-xs">⌘</span>K
                    </kbd>
                </Button>
            </div>
            <div className={cn("block lg:!hidden")}>
                <Button
                    variant="outline"
                    size="icon"
                    onClick={() => setOpen(true)}
                >
                    <FontAwesomeIcon icon={faSearch} className="h-[1.2rem] w-[1.2rem] scale-100 transition-all" />
                    <span className="sr-only"><Text path={i18nPath}>search</Text></span>
                </Button>
            </div>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogContent className="p-0">
                    <DialogHeader className="hidden">
                        <DialogTitle className="hidden"></DialogTitle>
                    </DialogHeader>
                    <Command>
                        <CommandInput
                            className="h-12"
                            placeholder={`Hey ${user.firstName}! Type a command or search...`}
                        />
                        <CommandList>
                        <CommandEmpty>{`Sorry ${user.firstName}. No results found. :(`}</CommandEmpty>
                        {(navigation && navigation.length) && (
                                <CommandGroup heading="Navigation">
                                    {navigation.filter((item : any) => !item.disabled).map((item : any, index : number) => {
                                        return (
                                            <NavCommandItem key={index} item={item} />
                                        )
                                    })}
                                </CommandGroup>
                            )}

                            {/* <CommandGroup heading="Suggestions">
                                <CommandItem>A23-HQ-3101-01-1-4</CommandItem>
                                <CommandItem>AT10000234234</CommandItem>
                                <CommandItem>Ziehl Abegg</CommandItem>
                            </CommandGroup>
                            <CommandSeparator />
                            <CommandGroup heading="Search">
                                <CommandItem>Last searched item 1</CommandItem>
                                <CommandItem>Last searched item 2</CommandItem>
                                <CommandItem>Last searched item 3</CommandItem>
                                <CommandItem>Last searched item 4</CommandItem>
                            </CommandGroup> */}
                        </CommandList>
                    </Command>
                </DialogContent>
            </Dialog>
        </div>
    )
}